import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {Router} from '@angular/router'
import { UserService } from '../../services/user/user.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  isLoginError : boolean = false;
  loading : boolean = false;

constructor(private router:Router,private userservice:UserService){}
  onRegister()
  {
    this.router.navigate(['register']);
  }
  OnSubmit(userName,password)
  {
    console.log(environment.apiUrl);
    console.log(userName);
    console.log(password);
    this.loading=true;
   this.userservice.userAuthentication(userName,password).subscribe((data : any)=>{
    console.log(data.access_token);
    localStorage.setItem('userToken',data.token);
    localStorage.setItem('name',data.name);
    localStorage.setItem('username',data.username);
    localStorage.setItem('id',data.id);
    localStorage.setItem('currentyear',data.currentyrId);
    this.loading=false;
    console.log(data);
    if(data.companyStatus==false)
    this.router.navigate(['initials']);
    else
    this.router.navigate(['dashboard']);
  },
  (err : HttpErrorResponse)=>{
    this.isLoginError = true;
    console.log(err);
    this.loading=false;
  });;
  } 
}
