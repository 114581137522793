import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateGuard } from './activate.guard';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { ChangepasswordComponent } from './views/changepassword/changepassword.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { InitialComponent } from './views/initial/initial.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
    
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
   } ,
   
  {
    path: 'initials',
    component: InitialComponent,
    data: {
      title: 'initials Details'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate:[ActivateGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'masters',
        loadChildren: () => import('./views/masters/masters.module').then(m => m.MasterModule)
      },
      {
        path: 'purchases',
        loadChildren: () => import('./views/purchases/purchases.module').then(m => m.PurchaseModule)
      }
      ,
      {
        path: 'sales',
        loadChildren: () => import('./views/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      } ,
      {
        path: 'expenses',
        loadChildren: () => import('./views/expenses/expenses.module').then(m => m.ExpenseModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
