import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class BasicSettingService {
    readonly rootUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getBasicSetting(companyId:string){
      return  this.http.get(this.rootUrl+'/api/BasicDetails/getBasicDetails/'+companyId);
     }
     getDocumentNumber(companyId:string){
      return  this.http.get(this.rootUrl+'/api/BasicDetails/getDocumentNo/'+companyId);
     }
     saveNumber(formData:FormData){
      return  this.http.post(this.rootUrl+'/api/BasicDetails/saveDocumentNo',formData);
     }
     updateBasicSetting(formData:FormData){
        return  this.http.post(this.rootUrl+'/api/BasicDetails/saveBasicDetails',formData);
       }
       saveRemark(formData:FormData){
        return  this.http.post(this.rootUrl+'/api/Remark/saveRemark',formData);
       }
       getRemark(companyId:string){
        return  this.http.get(this.rootUrl+'/api/Remark/getRemark/'+companyId);
       }
       deleteRemark(formData:FormData){
        return  this.http.post(this.rootUrl+'/api/Remark/deleteRemark',formData);
       }
       updateForamt(formData:FormData){
        return  this.http.post(this.rootUrl+'/api/BasicSettings/UpdateInvoiceFormat',formData);
       }
       getUnit(){
        return  this.http.get(this.rootUrl+'/api/BasicDetails/getUnits');
       }
       getBrand(){
        return  this.http.get(this.rootUrl+'/api/BasicDetails/getBrand');
       }
       getCategory(){
        return  this.http.get(this.rootUrl+'/api/BasicDetails/getCategory');
       }
       saveUnit(formdata:FormData){
        return  this.http.post(this.rootUrl+'/api/BasicDetails/saveUnit',formdata);
       }
       saveBrand(formdata:FormData){
       return  this.http.post(this.rootUrl+'/api/BasicDetails/saveBrand',formdata);
       }
       saveCategory(formdata:FormData){
        return  this.http.post(this.rootUrl+'/api/BasicDetails/saveCategory',formdata);
       }
       getSubCategory(){
        return  this.http.get(this.rootUrl+'/api/BasicDetails/getSubCategory');
       }
       saveSubCategory(formdata:FormData){
        return  this.http.post(this.rootUrl+'/api/BasicDetails/saveSubCategory',formdata);
       }
       getcurrentdocument(type: string,date:string) {
         return this.http.get(this.rootUrl + "/api/BasicDetails/getCurrentNo/" + type +"/"+date);
      }
      savetablecolumn(formdata:FormData){
        return  this.http.post(this.rootUrl+'/api/BasicDetails/savetablecolumn',formdata);
       }
       gettablecolumn(type: string) {
         return this.http.get(this.rootUrl + "/api/BasicDetails/getcolumntoshow/" + type);
      }
      getYear() {
        return this.http.get(this.rootUrl + "/api/BasicDetails/getyear");
     }
     getMenu() {
      return this.http.get(this.rootUrl + "/api/BasicDetails/getmenu");
   }
   getUserMenu(username:string) {
    return this.http.get(this.rootUrl + "/api/BasicDetails/getusermenu/"+username);
 }
  }
