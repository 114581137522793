import { Component, ElementRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Address } from '../../services/company/address.model';
import { CompanyService } from '../../services/company/comany.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.css']
})
export class InitialComponent implements OnInit {
  address:Address;
  closeResult = '';
  imageUrl:string="assets/img/avatars/no-logo.png";
  currentTab:number=0;
  preButton:boolean=false;
  fileToUpload:File=null;
  showImage:boolean=false;
  bAddressColl: Array<Address>  = [];
  sAddressColl: Array<Address> =  [];
  isbillingmodle:boolean=true;
  isedit:boolean=false;
  editIndex:number=0;
  modelTitle:string="Add Billing Address";
  modelButton:string="Add";
  modelSameTitle:string="Shipping";
  constructor(private companyService: CompanyService, private toastr: ToastrService,private router:Router, private elem: ElementRef,private modalService: NgbModal) { }

  ngOnInit(): void {
   this.showTab(0);
   this.resetForm();
  }
  resetForm() {
    this.address = {companyID:"",
    addressId:"",
    address:"",
    city:"",
    state:"",
    pincode:"",
    country:"",
    phone:"",
    mobile:"",
    email:"",
    defualt:false,
    createaddress:false};

   }
  handleFileInput(file:FileList)
  {
  this.fileToUpload=file.item(0);
  var reader=new FileReader();
  reader.onload=(event:any)=>{
    this.imageUrl=event.target.result;
    this.showImage=true;
  }
  reader.readAsDataURL(this.fileToUpload);
  }
  showTab(n) {
    var x = document.getElementsByClassName("tab");
    x[n].setAttribute('style','display:inline');
    if (n == 0) {
      this.preButton=false;
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
      this.preButton=true;
    }
    if (n == (x.length - 1)) {
      document.getElementById("nextBtn").innerHTML = "Submit";
    } else {
      document.getElementById("nextBtn").innerHTML = "Next";
    }
    //... and run a function that will display the correct step indicator:
    //fixStepIndicator(n)
  }
  nextPrev(n,form: NgForm) {
    console.log(form.value.companyname);
    var x = document.getElementsByClassName("tab");
    var l = document.getElementsByTagName("li");

    if (this.currentTab+n >= x.length) {
      console.log('tab'+this.currentTab);
      console.log('length',x.length)
      this.SaveInitials(form);
      return false;
    }
    x[this.currentTab].setAttribute('style','display:none');
    if(n==-1)
    l[this.currentTab].setAttribute('class','');
    else
    l[this.currentTab].setAttribute('class','complete');

    this.currentTab = this.currentTab + n;
    l[this.currentTab].setAttribute('class','active');
    
    this.showTab(this.currentTab);
  }

  showNav(navid)
  {
    console.log(navid);
    if(navid==1)
    {
      document.getElementById("tabbilling").setAttribute('class','nav-item nav-link active');
      document.getElementById("navbilling").setAttribute('class','tab-pane fade show active');
      document.getElementById("tabshipping").setAttribute('class','nav-item nav-link');
      document.getElementById("navshipping").setAttribute('class','tab-pane fade');
    }
    else{
    document.getElementById("tabshipping").setAttribute('class','nav-item nav-link active');
    document.getElementById("navshipping").setAttribute('class','tab-pane fade show active');
    document.getElementById("tabbilling").setAttribute('class','nav-item nav-link');
    document.getElementById("navbilling").setAttribute('class','tab-pane fade');
    }
  }
  SaveInitials(form:NgForm)
  {
    const formData: FormData = new FormData();
    formData.append("LogoImage", this.fileToUpload);
    formData.append("companyDetails", JSON.stringify(form.value));
    formData.append("billing",JSON.stringify(this.bAddressColl));
    formData.append("shipping",JSON.stringify(this.sAddressColl));
    this.companyService.addCompany(formData)
      .subscribe((data: any) => {
        console.log(data);
        this.router.navigate(['dashboard']);
        //  if (data.status == "true") {
         // console.log(data);
         // this.resetForm(form);
          //this.toastr.success(data.message);
        // }
        // else
        //   this.toastr.error(data.message);
        // console.log(data.message);
      });
  }
  open(content,id:string) {
     if(id=="Billing")
      this.isbillingmodle=true;
     else
     this.isbillingmodle=false;
      if(this.isedit==true)
      {
        this.modelTitle="Edit "+ id + " Address";
        this.modelButton="Edit";
      }
      else
      {
        this.modelTitle="Add "+ id + " Address";
        this.modelButton="Add";
        this.resetForm();
      }
      this.modelSameTitle=id;
      this.modalService.open(content, {size: 'md',ariaLabelledBy: 'modal-basic-title'});
   }

   OnSubmit(form: NgForm) {
     let addr=form.value;
     console.log(this.isbillingmodle);
     if(this.isedit==true)
     {
       if(this.isbillingmodle==true)
       {
        this.bAddressColl[this.editIndex].address=addr.address;
        this.bAddressColl[this.editIndex].country=addr.country;
        this.bAddressColl[this.editIndex].state=addr.state;
        this.bAddressColl[this.editIndex].city=addr.city;
        this.bAddressColl[this.editIndex].pincode=addr.pincode;
        this.bAddressColl[this.editIndex].email=addr.email;
        this.bAddressColl[this.editIndex].phone=addr.phone;
        this.bAddressColl[this.editIndex].mobile=addr.mobile;
        this.bAddressColl[this.editIndex].createaddress=addr.createaddress;
        this.bAddressColl[this.editIndex].defualt=addr.defualt;
       }
       else{
        this.sAddressColl[this.editIndex].address=addr.address;
        this.sAddressColl[this.editIndex].country=addr.country;
        this.sAddressColl[this.editIndex].state=addr.state;
        this.sAddressColl[this.editIndex].city=addr.city;
        this.sAddressColl[this.editIndex].pincode=addr.pincode;
        this.sAddressColl[this.editIndex].email=addr.email;
        this.sAddressColl[this.editIndex].phone=addr.phone;
        this.sAddressColl[this.editIndex].mobile=addr.mobile;
        this.sAddressColl[this.editIndex].createaddress=addr.createaddress;
        this.sAddressColl[this.editIndex].defualt=addr.defualt;
       }
  
     }
     else{
      if(this.isbillingmodle==true || addr.createaddress==true)
      this.bAddressColl.push(addr);
      if(this.isbillingmodle==false || addr.createaddress==true)
        this.sAddressColl.push(addr);
     }
        this.resetForm();
   }
 AddAddress(content,type:string)
   {
    this.isedit=false;
    this.open(content,type);
   }
   deleteAddress(index:number,type:string)
   {
     if(type=="Billing")
    this.bAddressColl.splice(index, 1);
    if(type=="Shipping")
    this.sAddressColl.splice(index, 1);
   }
   editAddress(index:number,type:string,content)
   {
    if(type=="Billing")
    this.address= this.bAddressColl[index];
    else
    this.address=this.sAddressColl[index];
    this.isedit=true;
    this.editIndex=index;
    this.open(content,type);
   }
}
