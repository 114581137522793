import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  Response } from "@angular/http";
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import { User } from './user.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly rootUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  registerUser(user : User){
    const body: User = {
      UserName: user.Email,
      Password: user.Password,
      Email: user.Email,
      Name: user.Name
    }
    var reqHeader=new HttpHeaders({'No-Auth':'True'});
    return this.http.post(this.rootUrl + '/api/Authenticate/Register', body,{headers:reqHeader});
  }
  registerOtherUser(form : FormData){
    return this.http.post(this.rootUrl + '/api/Authenticate/registerstaff', form);
  }
  userAuthentication(userName, password) {
    var data = {"Username":  userName  ,"Password": password };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
    console.log(this.rootUrl);
    return this.http.post(this.rootUrl + '/api/Authenticate/Login', data, { headers: reqHeader });
  }
  changepassword(form: FormData) {
    return this.http.post(this.rootUrl + '/api/Authenticate/changepassword', form);
  }
  getUserClaims(){
    return  this.http.get(this.rootUrl+'/api/Authenticate/GetUserClaims');
   }
   getUsers(){
    return  this.http.get(this.rootUrl+'/api/Authenticate/GetUsers');
   }
  isUserLogin():boolean
  {
    if (localStorage.getItem('userToken') != null)
    return true;
    return false;
  }
  deletestaff(form: FormData) {
    return this.http.post(this.rootUrl + '/api/Authenticate/deletestaff', form);
  }
}
