import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  Response } from "@angular/http";
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import { Company } from './company.model';
import { environment } from '../../../environments/environment';
import { Command } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
    readonly rootUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getCompany(id:string){
      return  this.http.get(this.rootUrl+'/api/Company/getCompany/'+id);
     }
     getCompanyList(){
       console.log(this.rootUrl);
      return  this.http.get(this.rootUrl+'/api/Company/getCompanyList');
     }
     getCompanybyID(id:string){
      return  this.http.get(this.rootUrl+'/api/Company/GetCompanybyID/'+id);
     }
    addCompany(formData:FormData){
      return this.http.post(this.rootUrl + '/api/Company/saveCompany', formData);
    }
    saveAddress(formData:FormData){
      console.log(formData);
      return this.http.post(this.rootUrl + '/api/Company/saveAddress', formData);
    }
    editCompany(formData:FormData){
      return this.http.post(this.rootUrl + '/api/Company/EditCompany', formData);
    }
    updateCompany(formData:FormData){
      return this.http.post(this.rootUrl + '/api/Company/updateCompany', formData);
    }
    getAddressbyID(id:string,type:string){
      return  this.http.get(this.rootUrl+'/api/Company/getCompanyAddressById/'+id+"/" +type);
     }
     deleteAddressbyID(formData:FormData){
      return  this.http.post(this.rootUrl+'/api/Company/deleteAddress/',formData);
     }

     saveBank(formData:FormData){
       //console.log(bnk);
     
      return this.http.post(this.rootUrl + '/api/Company/SaveBank', formData);
    }
    getBankID(id:string){
      return  this.http.get(this.rootUrl+'/api/Company/getcompanyById/'+id);
     }
     deleteBankbyID(formData:FormData){
      return  this.http.post(this.rootUrl+'/api/Company/DeleteBank/',formData);
     }
     GetBank(){
      return  this.http.get(this.rootUrl+'/api/Company/GetBank');
     }
  }