import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRoutingModule } from './app.routing';
import { UserService } from './services/user/user.service';
@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {

constructor(private userservice:UserService,private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.userservice.isUserLogin())
    {
      return true;
    }
    else
    {
      this.router.navigate(['login']);
    }
  }
  
}
