import {Component, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { INavData } from '@coreui/angular';
import { BasicSettingService } from '../../services/basicsetting/basicsetting.service';
import { CompanyService } from '../../services/company/comany.service';
import { UserService } from '../../services/user/user.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent  {
  userClaims: any;
  public sidebarMinimized = false;
  public navItems: INavData[];
  _year:any[];
  _company:any[];
  selected:any;
  companyselected:any;
  constructor(private router: Router,private userservice:UserService,private basicSettingService:BasicSettingService,private companyservice:CompanyService,private renderer: Renderer2) { }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  ngOnInit() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   
    if(localStorage.getItem('name') !=null)
   this.userClaims=localStorage.getItem('name');
   this.loadMenu();
   this.loadYear();
   this.loadCompany();
   this.selected=localStorage.getItem('currentyear');
   this.companyselected=localStorage.getItem('companyId');
  }
  loadMenu(){
    
    this.basicSettingService.getMenu().subscribe((data: any) => {
      this.navItems = [
        {
          title: true,
          name: 'Features'
        },
        {
          name: 'Dashboard',
          url: '/dashboard',
          icon: 'icon-speedometer',
          badge: {
            variant: 'info',
            text: 'NEW'
          }
        }
      ];
  let datan=   data.map(item => item.pageSection)
  .filter((value, index, self) => self.indexOf(value) === index);

  console.log(datan);
    //  let masters=data.pages.filter(at => at.pageSection === 'Masters');
      if(datan.length>0)
      {
        datan.forEach((item)=>{
          
          console.log(item);
            let  masters= data.filter(at => at.pageSection === item);
            console.log(masters);
            var  child=[];
            masters.forEach((items) => {
              console.log(items);
              child.push({ name: items.pageName,
              url: items.pageUrl,
              icon: items.pageIcon});
              console.log(child);
            });
           if(child.length==1)
           {
            this.navItems.push(child[0]);
           }
           else{
          this.navItems.push({ name: item,
        url: '/'+item,
        icon: 'icon-notebook',
        children: child
        });
      }
        console.log(this.navItems);
      })
      
    }
  })
  
  }
  loadYear(){
    this.basicSettingService.getYear().subscribe((data: any) => {
      this._year = data;
      console.log(data);
    });
  }
  loadCompany(){
    console.log('hi');
    this.companyservice.getCompanyList().subscribe((data: any) => {
      this._company = data;
      console.log(data);
      if(localStorage.getItem('companyId')==null || localStorage.getItem('companyId')=="")
      {
        localStorage.setItem('companyId',data[0].companyId);
        this.companyselected=localStorage.getItem('companyId');
      }
      console.log(data[0].companyId);
    },(error:any)=>{
      console.log(error);
    });
  }
  companyindexchange()
  {
    localStorage.setItem('companyId',this.companyselected);
    let cururl= this.router.url;
    this.router.navigateByUrl('/dashboard/redirectpage', { skipLocationChange: true }).then(() => {
      console.log(cururl);
      this.router.navigate([cururl]);
  });  
  }
  logout()
  {
    localStorage.removeItem('userToken');
    localStorage.removeItem('name');
    localStorage.removeItem('username');
    localStorage.removeItem('id');
    localStorage.removeItem('currentyear');
    localStorage.removeItem('companyId');
   this.router.navigate(['login']);
  }
  updateBodyClass() {
    const body = document.body;
    if (body.classList.contains('sidebar-lg-show')) {
      if (body.classList.contains('sidebar-minimized')) 
      body.classList.value='app ng-tns-0-0 header-fixed sidebar-fixed sidebar-minimized brand-minimized';
      else
      body.classList.value='app ng-tns-0-0 header-fixed sidebar-fixed';
    } else {
      if (body.classList.contains('sidebar-minimized')) 
      body.classList.value='app ng-tns-0-0 header-fixed sidebar-fixed sidebar-lg-show  sidebar-minimized brand-minimized';
      else
      body.classList.value='app ng-tns-0-0 header-fixed sidebar-fixed sidebar-lg-show';
    }
  }
  updateBodyClassForminimize() {
    const body = document.body;

      if (body.classList.contains('sidebar-minimized')) 
      body.classList.value=body.classList.value.replace(' sidebar-minimized brand-minimized','');
      else
      body.classList.value=body.classList.value+' sidebar-minimized brand-minimized';
    
  }
}
