import { Component } from '@angular/core';
import {Router} from '@angular/router'
import { User } from '../../services/user/user.model';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  user: User;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private userService: UserService, private toastr: ToastrService,private router:Router){}
  ngOnInit() {
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.user = {
      UserName: '',
      Password: '',
      Email: '',
      Name: ''
    }
  }

  OnSubmit(form: NgForm) {
    this.userService.registerUser(form.value)
      .subscribe((data: any) => {
        console.log(data);
        if (data.status == true) {
          this.resetForm(form);
          this.toastr.success('User registration successful');
        }
        else
        this.toastr.error('User already registered');
      });
  }
  onLogin()
  {
    this.router.navigate(['login']);
  }

}
