import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth') == "True")
            return next.handle(req.clone());

        if (localStorage.getItem('userToken') != null) {
            // const headers = new HttpHeaders({
            //     'Authorization': 'token 123',
            //     'WEB-API-key': environment.webApiKey,
            //     'Content-Type': 'application/json'
            //   });
            if(localStorage.getItem('companyId')==null)
               localStorage.setItem('companyId',"");

            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken')).set("companyId",localStorage.getItem('companyId')),
            });
            return next.handle(clonedreq)
                .do(
                succ => { },
                err => {
                    if (err.status === 401)
                        this.router.navigateByUrl('/login');

                }
                );
        }
        else {
            this.router.navigateByUrl('/login');
        }
    }
}